import { defineStore, acceptHMRUpdate } from "pinia";
import apolloClient from "../plugins/apollo";
import { addFavoriteTrack, removeFavoriteTrack } from "@/gqls/mutations";
import { getFavorites } from "@/gqls/auth";

export const useFavoriteStore = defineStore("favorite", {
  state: () => ({
    favorites: [], // Array to store favorite tracks
  }),

  getters: {
    /**
     * Getter to retrieve all favorite tracks
     */
    getFavoriteTracks: (state) => state.favorites,
     /**
     * Check if a track is in favorites
     * @returns {Function} A function that takes a trackId and returns true if the track is in favorites
     */
     isFavorite: (state) => (trackId) => {
        return state.favorites.some((track) => track.id === trackId);
      },
  },

  actions: {
    /**
     * Add a single track to favorites
     * @param {Object} track - The track object of the track to be added
     */
    async addFavoriteTrackAction(track) {
      let trackId = track.id;
    
      return new Promise((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: addFavoriteTrack,
            variables: { trackId },
          })
          .then((response) => {
            // Extract the favoriteTrack from the response
            let newTrack = response.data.addFavoriteTrack.favoriteTrack;
    
           
    
            // Add the track to the favorites array
            this.favorites = [...this.favorites, newTrack];
    
            resolve(response);
          })
          .catch((error) => {
            console.error("Error adding to favorites:", error);
            reject(error);
          });
      });
    }
    ,
    
    /**
     * Remove a single track from favorites
     * @param {String} trackId - The ID of the track to be removed
     */
    async removeFavoriteTrackAction(trackId) {
      return new Promise((resolve, reject) => {
        apolloClient
          .mutate({
            mutation: removeFavoriteTrack,
            variables: { trackId },
          })
          .then((response) => {
            // Update the local state by filtering out the removed track
            this.favorites = this.favorites.filter((track) => track.id !== trackId);
            console.log("Removed from favorites:", response);
            resolve(response);
          })
          .catch((error) => {
            console.error("Error removing from favorites:", error);
            reject(error);
          });
      });
    },

    /**
     * Fetch user favorite tracks from the server
     * @param {Number} page - The page number for paginated results (default 1)
     * @param {Number} limit - The number of results per page (default 20)
     */
    async fetchFavoritesAction(page = 1, limit = 20) {
      return new Promise((resolve, reject) => {
        apolloClient
          .query({
            query: getFavorites,
            fetchPolicy: "network-only",
            variables: { page, limit },
          })
          .then((res) => {
            this.favorites = res.data.getUserFavoriteTracks.data; // Assuming `data` contains the array of tracks
            resolve(this.favorites);
          })
          .catch((error) => {
            console.error("Error fetching favorites:", error);
            reject(error);
          });
      });
    },
  },
});

if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(acceptHMRUpdate(useFavoriteStore, import.meta.webpackHot));
}
